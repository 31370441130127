.logo_mobile {
    /* display: none; */
    margin: 0;
}

.menu-title{
    display: none;
}

.menu-title img {
    /* display: none; */
    width: 15px;
}

.container_profile {
position: fixed;
top: 0;
right: 0;
box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
}

@media (min-width: 1025px) {
    .container_logo {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        flex-direction: row;
    }
}

@media (max-width: 1600px) {
  .breadcrumb {
    max-width: 85%;
  }
}

@media (max-width: 800px) {
  .breadcrumb {
    max-width: 75%;
  }
}

@media (max-width: 1024px) {
    .container_logo {
        flex-direction: column-reverse !important;
    }
    .h4_crm {
        margin-top: 10px;
    }
    /*#topnav-hamburger-icon {*/
    /*    margin-top: 10px;*/
    /*}*/
}


@media (max-width: 767px) {
    /* .logo_mobile, .menu-title img {
        display: block;
    } */
    .menu-title{
        display: flex;
        justify-content: space-between;
        align-items: center;
    
    }
  }
  