.cart, .cart_lg {
    border-radius: 5px;
    /* border: var(--vz-border-width) solid var(--vz-input-border-custom); */
    /* border: 1px black solid; */
    padding: 15px;
    width: 800px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.cart {
    width: 31%;
}

.cart_lg {
    align-items: start;
}
.file_img {
    max-height: 40px;
    width: auto;
    max-width: 100px;
}
.form {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: var(--vz-border-width) solid var(--vz-input-border-custom);
    border-radius: 5px;
    padding: 3px 5px;
}

.form_el {
    display: flex;
    justify-content: space-between;
    margin: 30px 0;
    width: 800px;
}

h5 {
    color: black;
}

.button {
    border-radius: 5px;
    border: var(--vz-border-width) solid var(--vz-input-border-custom);
    margin-top: 35px;
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 10px;
}

/* .container_input_params { */
    /*display: flex;*/
    /*align-items: flex-end;*/
    /*justify-content: flex-end;*/
    /*width: 100%;*/

/* } */
/* .container_params { */
    /*width: 35%;*/
    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
/* } */

.add_new_params {
    border: var(--vz-border-width) solid var(--vz-input-border-custom);
    border-radius: 5px;
    /* width: 100%; */
    margin-top: 5px;
    padding: 5px 5px;
    background-color: white;
}

.input_small {
    height: 30px;
}

.input_params {
    height: 30px;
    width: 200px;
    margin-top: 15px;
}

.span_product {
    width: 70%;
    display: flex;
    justify-content: space-between;
}

.ul {
    font-size: 18px;
    list-style-type: none;
    display: flex;
    padding: 20px 0;
    flex-direction: column;
}

.open_input {
    width: 35%;
    padding: 10px;
}

.form_li {
    font-size: 20px;
    list-style-type: none;
    display: flex;
    /* margin: 0; */
    padding: 20px 0;
    width: 47%;
    justify-content: space-between;
}

.form_control {
    font-size: 20px;
}

.card {
    padding: 0 10%;
}


/* .modal_product {
    min-width: 650px !important;
} */
.table_modal {
    width: 100%;
}
.table_modal thead th,
.table_modal tbody td {
    padding: 7px 7px 7px 0!important;
}
.table_modal tbody td:last-child {
    padding: 7px 10px 7px 2px!important;
}
.smaller_input {
    line-height: 1.4;
    resize: none;
    padding: 0.25rem 0.5rem;
}

/*@media (max-width: 1710px) {*/
/*    .card {*/
/*        padding: 0 5%;*/
/*    }*/
/*}*/
/*@media (max-width: 1370px) {*/
/*   .form_el {*/
/*    width: 82%;*/
/*   }*/
/*   .params {*/
/*    width: 100%;*/
/*   }*/
/*}*/

@media (max-width: 1250px) {
   .cart_lg, .form_el {
    width: 100%;
   }
}

@media (max-width: 770px) {
    .cart {
        margin: 5px auto;
        width: 100%;
    }
    .form-label {
        margin-bottom: 0;
    }

    .form_el {
        margin: 15px 0;
        display: flex;
        flex-direction: column;
    }
}

@media (max-width: 450px) {
    .cart {
        margin: 0 auto;
        /* width: 100%; */
        border-bottom: 0;
        border-top: 0;
        border-radius: 0;
    }
    /* .cart:last-child {
        border-bottom: var(--vz-border-width) solid var(--vz-input-border-custom);
    }
    .cart:first-child {
        border-top: var(--vz-border-width) solid var(--vz-input-border-custom);
    } */
}