/* LOADER */
#progress-container {
    position: relative;
    margin: 30px auto 0 auto;
    width: 70%;
    background-color: #f8fffd;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
}
#progress-bar span {
    color: #4d7699;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    /*height: 15px;*/
    /*background-color: #4ca0af;*/
    /*width: 0;*/
    /*text-align: center;*/
    /*color: white;*/
    /*line-height: 30px;*/
    /*border-radius: 5px;*/
}

/*.custom-widget-card {*/
/*    padding: 10px; !* Уменьшаем паддинг *!*/
/*    border-radius: 8px; !* Закругленные углы (если требуется) *!*/
/*    min-height: auto; !* Убираем фиксированную высоту, если есть *!*/
/*}*/

/*.custom-widget-card h5 {*/
/*    font-size: 10px; !* Уменьшаем размер текста заголовка *!*/
/*    margin-bottom: 0;*/
/*}*/

/*.custom-widget-card h2 {*/
/*    font-size: 10px; !* Уменьшаем размер текста счетчика *!*/
/*}*/

