.table-container {
  overflow-x: auto;
}

.event-log-table {
  width: 100%;
  border-collapse: collapse;
}

.event-log-table th, .event-log-table td {
  /*border: 1px solid #ddd;*/
  padding: 8px;
}

.event-log-table th {
  /*background-color: #f2f2f2;*/
  font-weight: bold;
  text-align: left;
}

/*.event-log-table tbody tr:hover {*/
/*  background-color: #f5f5f5;*/
/*}*/

/*.even-row {*/
/*  background-color: #fff;*/
/*}*/

/*.odd-row {*/
/*  background-color: #f9f9f9;*/
/*}*/

.even_log_table__tbody tr td:last-child {
  /*padding: 6px !important;*/
  line-height: 1.2;
}

.even_log_table__tbody tr td:not(td:last-child) {
  padding: 16px 6px!important;
}

.even_log_table__tbody tr td:last-child textarea {
  width: 100%;
  height: 100%;
  outline: none;
  border: 0;
}
/*.even_log_table__tbody tr:hover td:last-child textarea {*/
/*  background-color: #f5f5f5;*/
/*}*/
/* ------------- */
.event-log-table {
  width: 100%;
  border-collapse: collapse;
}

.event-log-table th, .event-log-table td {
  /*border: 1px solid #ddd;*/
  /*padding: 8px!important;*/
  white-space: nowrap; /* Prevent wrapping */
}

.event-log-table th {
  /*background-color: #f2f2f2;*/
  /*font-weight: bold;*/
  text-align: left;
}

.event-log-table .textarea-td {
  width: 50%;
}

.textarea-td textarea {
  width: 100%;
  box-sizing: border-box;
  resize: none; /* Allow vertical resizing */
}

/*.event-log-table tbody tr:hover {*/
/*  background-color: #f5f5f5;*/
/*}*/

/*.even-row {*/
/*  background-color: #fff;*/
/*}*/

/*.odd-row {*/
/*  background-color: #f9f9f9;*/
/*}*/

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .event-log-table th,
  .event-log-table td {
    padding: 6px!important;
    white-space: pre-wrap;
  }
  .even_log_table__tbody tr td:not(td:last-child) {
    padding: 6px!important;
  }

  .textarea-td textarea {
    width: 90%; /* Adjust width for smaller screens */
  }
}
