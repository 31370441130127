
.table_with_checkbox thead tr th:first-child {
    width: 3%;
}

.filter_button {
    margin-left: 80px;
    min-width: 102px;
}

.filter_section {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;

    justify-content: flex-start;
    min-height: 150px;
    width: 100%;
    background-color: rgba(243, 243, 243, 0.3);
    margin-top: 5px;
    border-bottom: 1px solid #e9ebec;
}

.filter_section h2 {
    font-weight: bold;
    margin-right: 60px;
}
.filter_label {
    font-size: 16px;
    font-weight: bold;
}
.filter_element {
    padding-right: 20px;
    padding-left: 20px;
    color: #495057;
    margin-top: 10px;
    /*margin-bottom: 5px;*/
    width: 280px;
    margin-right: 30px;
}
.filter_element.only {
    width: 60%;
}
.filter_element:last-child {
    margin-right: 0;
}
.filter_element_date {
    margin-right: 30px;
    color: #495057;
    margin-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
}
.date_range {
    min-width: 253px;
}

.sm_loader::after {
    content: '';
    background-color: transparent;
    background-image: url('../../../src/assets/loader.gif');
    background-position: center;
    background-size: cover;
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    left: 135%;
    transform: translate3d(-50%, -50%, 0);
}

@media (max-width: 1460px) {
    .filter_element {
        margin-right: 5px;
        /*padding-left: 5px;*/
        /*padding-right: 5px;*/
        width: 230px;
    }
    .filter_element_date {
        margin-right: 5px;
    }
}

@media (max-width: 900px) {
    .filter_element.only {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .filter_button {
        margin-left: 6px!important;
    }
    .date_range {
        min-width: unset;
    }
    .filter_element {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
    .filter_element_date {
        padding-left: 0;
        padding-right: 0;
        margin-right: 0;
    }
    .filter_section {
        padding: 10px;
    }
}