.filter_section {
  display: flex;
  /*align-items: flex-start;*/
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}

.sum_filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sum_filter,
.date_range,
.filter_element_category,
.title,
.filter_element {
  padding: 10px 15px;
  /*margin-bottom: 40px;*/
}

/*.date_range {*/
/*  min-width: 253px;*/
/*}*/

/* MODAL  */

.date_range_modal,
.sum_modal,
.modal_category {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.date_modal {
  display: flex;
}

.form_modal {
  width: 50%;
  display: flex;
}

/* .width {
  max-width: 90%;
} */

.table_revolutions tfoot tr td,
.table_profit tfoot tr td,
.table_profit tbody tr td,
.table_profit thead tr th,
.table_revolutions thead tr th,
.table_revolutions thead tr td,
.table_revolutions tbody tr td {
  text-align: center;
}

.table_profit thead tr th,
.table_revolutions thead tr th,
.table_expenses thead tr th {
  font-weight: normal;
  font-size: 15px;
}

/* th, tr, td {
  text-align: center;
}  */

/* ANALYTICS PROFIT */

.filter_label {
  font-size: 16px;
  font-weight: bold;
  color: #495057;
}

.title_thead_expenses {
  border-bottom: 1px solid #575d703b;
  line-height: normal;
}

.title_thead {
  line-height: normal;
  position: sticky;
  z-index: 2;
  /*top: -1px;*/
  top: 0;
  background-color: white;
  /* border-top: 1px solid #575d703b;
  border-bottom: 1px solid #575d703b; */
}

.tfoot {
  position: sticky;
  z-index: 2;
  background-color: white;
  bottom: 0;
}
.tfoot tr th {
  border-top: 1px solid red !important;
}

.title_thead tr,
.title_thead tr {
  border-bottom: none !important;
  border: 0 !important;
}
.title_thead tr th {
  border: 0 !important;
}
.title_thead {
  border-top: 0 !important;
}
/*
.title_thead tr::before, 
.title_thead tr::after{
  border-bottom: 0.2px rgba(131, 209, 253, 0.6) solid;

} */

.table_container {
  max-height: 400px;
  overflow-y: auto;
  position: relative;
  /*width: 60%;*/
}

.title_thead::before,
.title_thead::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(131, 209, 253, 0.6);
}

.table_revolutions thead tr th {
  font-weight: 500;
}

.border_l {
  /* border-right: solid 1px #b0dffc; */

  border-left: solid 1px #b0dffc;
}
.border_lr {
  border-right: solid 1px #b0dffc;

  border-left: solid 1px #b0dffc;
}

.scroll {
  overflow-y: scroll;
}

.border_l {
  border-left: solid 1px #b0dffc;
}
.table_expenses tfoot tr:last-child,
.table_revolutions tfoot tr:last-child,
.table_profit tfoot tr:last-child {
  border-bottom: none;
}
.table_profit tbody tr:last-child {
  border-bottom: none;
}
.table_profit thead tr {
  border-bottom: 0 !important;
  border-top: 0 !important;
}
.table_expenses thead::before,
.table_expenses thead::after {
  border-bottom: 0 !important;
  border-top: 0 !important;
}
.table_profit tbody tr:first-child,
.table_expenses tbody tr:first-child {
  border-top: 2px solid #cbcbcb;
}
.table_profit tfoot tr:last-child,
.table_expenses tfoot tr:last-child {
  border-top: 2px solid #cbcbcb;
}
.table_profit {
  /*border-collapse: unset;*/
}
.table_expenses {
  border-collapse: unset;
}

.delete_inline {
  margin: 0 15px;
  position: relative;
  cursor: pointer;
}
.delete_inline img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.li_statuses input {
  /*margin-right: 10px;*/
  height: 30px;
  padding: 5px;
}
.li_statuses select {
  height: 30px;
  min-width: 130px;
  width: 130px;
  padding: 5px;
}
.li_statuses {
  position: relative;
  margin-bottom: 8px;
}

@media (max-width: 1025px) {
  .table_container {
    /*width: 100%;*/
  }
}

@media (max-width: 500px) {
  .form_modal {
    width: 70%;
    margin-left: 10px;
  }
}

@media (max-width: 410px) {
  .width {
    min-width: 100%;
  }
}

@media (max-width: 400px) {
  .date_range_modal,
  .sum_modal,
  .modal_category {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
  }
  .form_modal {
    width: 100%;
    margin: 0;
  }
  .filter_section {
    flex-direction: column;
    align-items: stretch;
  }
}
