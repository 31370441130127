
.container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

@media(min-width: 1340px) {
  .container {
    width: 80%;
  }
}

@media(min-width: 1500px) {
  .container {
    width: 69%;
  }
}


@media(min-width: 1650px) {
  .container {
    width: 60%;
  }
}


@media(min-width: 1800px) {
  .container {
    width: 55%;
  }
}