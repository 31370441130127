.modal-print {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-print-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    text-align: center;
}

.form-group {
    margin-bottom: 20px;
    text-align: left;
}

.modal-print-actions button {
    margin-top: 10px;
    margin-right: 10px;
}