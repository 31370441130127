.navigate_btns {
  display: block;
  max-width: 50%;
  font-size: 17px;
}
.navigate_btns__card {
  transition: color 0.2s ease-in-out, background-color 0.2s;
}
.spacer {
  margin-bottom: 110px;
}
.navigate_btns__text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.navigate_btns__text img {
  margin-right: 8px;
  margin-bottom: 2px;
  max-width: 20px;
  height: auto;
}

.edit_btn {
  position: absolute;
  top: 20%;
  right: 2%;
  cursor: pointer;
}
.accept_btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  cursor: pointer;
}

.td_p,
.th_p {
  padding: 20px !important;
}

/*.invites {*/
/*    margin-left: 5rem;*/
/*    margin-right: 5rem;*/
/*}*/
/* ---------- MODAL ------------ */
.header_modal {
  display: flex;
  justify-content: space-between;
}

.cart {
  border-radius: 5px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  /* border: 1px black solid; */
  padding: 10px;
  width: 800px;
  display: flex;
  margin: 5px;
  align-items: flex-start;
  flex-direction: column;
}
.delete_inline {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 3%;
    transform: translateY(-50%);
}

.form_name {
  display: flex;
  justify-content: space-between;
}

.check {
  margin: 4px 10px 0 0;
  border-radius: 50% !important;
}

.user_type span {
  font-size: 16px;
  font-weight: 500;
}
.choose_user_type {
  margin-top: 30px;
}
.user_type p {
  font-size: 16px;
  font-style: italic;
  text-decoration: underline;
}

.bold_name {
  font-weight: 500;
}

.table_settings tbody {
  font-size: 16px;
}

.input_prod,
.search_prod {
  display: flex;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: 5px;
}
.feedback {
  margin-top: 5px; /* Adjust the margin as needed */
  margin-bottom: 5px;
}
.input_prod {
  width: 80%;
}
.search_prod {
  /* width: 40%; */
  align-items: center;
  padding: 0 10px;
}
.table_customer {
  width: 100%;
}
.table_customer thead tr th,
.table_customer tbody tr th {
  display: flex;
  justify-content: center;
}

.cart_organization {
    padding: 10px 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.form_name_organization {
  flex-direction: column;
}

@media (max-width: 1700px) {
  .header_modal {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
  }
}
@media (max-width: 900px) {
  .table_div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .td_p,
  .th_p {
    padding: 15px 20px !important;
  }
  .role_span {
    padding-right: 35px;
  }
}
@media (max-width: 770px) {
  .navigate_btns {
    max-width: 100%;
  }
}

@media (min-width: 700px) {
  .modal_product {
    min-width: 650px;
  }
  .padding_modal {
    padding: 1.25rem 2rem;
  }
}

@media (max-width: 495px) {
  .form_name {
    flex-direction: column;
  }
  .cart {
    width: auto;
  }
}
