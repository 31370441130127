.textarea_card {
    font-size: 15px;
    border: 2px solid #ced4da;
}
.variable {
    font-size: 15px;
}
.variable:hover {
    background-color: #f7f7f7;
    cursor: pointer;
}
.textarea_card:active {
    border: 2px solid darkcyan;
}
.desc {
    font-size: 13px;
}

@media (max-width: 1024px) {
    .delivery-toggle-group {
        display: flex;
        flex-wrap: wrap;
        gap: 8px; /* Расстояние между кнопками */
    }

    .delivery-toggle-group .MuiToggleButton-root {
        flex: 1 1 calc(50% - 8px); /* Две кнопки в строке */
        min-width: 120px; /* Минимальная ширина */
    }
    .delivery-toggle-group .MuiToggleButton-root {
        border: 1px solid rgba(0, 0, 0, 0.12) !important;
        border-radius: 5px !important;
    }
}

@media (max-width: 720px) {
    .delivery-toggle-group .MuiToggleButton-root {
        flex: 1 1 100%; /* Одна кнопка в строке на маленьких экранах */
    }
}

/*@media (max-width: 480px) {*/
/*    */

/*}*/
