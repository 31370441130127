h6 {
  color: black;
  font-weight: 700;
}
.header_reception {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
}

.header_form {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
  /* margin-bottom: 40px; */
}
.search_results_block {
  padding: 5px;
}

.va .search_results_block p {
  margin: 0;
  font-size: 16px;
  cursor: pointer;
}

select,
option {
  cursor: pointer;
}

.submit_block {
  display: flex;
  justify-content: flex-end;
}
.submit {
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  padding: 10px 40px;
  background-color: white;
  border-radius: 5px;
  font-size: 16px;
}

.order_products {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.order_products:last-child {
  margin-bottom: 0;
}
.order_products:last-child {
  margin-bottom: 0;
}
.circle {
  width: 30px;
  height: 30px;
  /*width: 25px;*/
  /*height: 25px;*/
  /*background-color: #3498db;*/
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  /*color: #fff;*/
}
.circle_small {
  margin-right: 6px;
  position: absolute;
  bottom: 2px;
  left: 4px;

  width: 22px;
  height: 22px;
  /*background-color: #3498db;*/
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transform: translateX(5px);
  font-size: 9px;
  /*color: #fff;*/
}

.cart_block {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: space-between;
}
.delete_inline {
  margin: 0 15px;
  position: relative;
  cursor: pointer;
}
.delete_inline img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.cart {
  border-radius: 5px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  /* border: 1px black solid; */
  padding: 10px;
  /* font-size: 16px !important; */
  width: 70%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.search,
.print,
.add_prod {
  border: 0.7px solid rgba(53, 50, 50, 0.7);
  /*font-size: 18px;*/
  border-radius: 2px;
  padding: 5px 15px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  appearance: none;
  border-radius: var(--vz-border-radius);
}

.results tbody tr:last-child {
  border-bottom: none;
}

.add_prod {
  border: none;
}
.print {
  font-size: 47px;
  padding: 10px 26px;
  cursor: pointer;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
}

.provider {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.table-responsive,
.comment_block {
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  padding: 20px;
  border-radius: 5px;
}

.input_prod,
.search_prod {
  display: flex;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: 5px;
}
.input_prod {
  width: 80%;
}
.search_prod {
  /* width: 40%; */
  margin-top: 10px;
  align-items: center;
  padding: 0 10px;
}

.catalog {
  color: #0089d0;
  background-color: white;
  font-size: 18px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: 5px;
  transition: background-color .2s ease-in-out;

  padding: 1px 15px;
}
.catalog:hover {
  background-color: #ebfbff;
}
.catalog:disabled {
  color: #5fbff1;
  background-color: #e8e8e8;
}

h4 {
  color: black;
}

.input_prod {
  margin-right: 10px;
}

.date {
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  border-radius: 5px;
  padding: 0.5rem 0.9rem;
  width: auto;
}
.select_provider {
  padding: 10px;
  width: 300px;
  margin: 24px;
  /*font-size: 18px;*/
  border-radius: 5px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
}

.select_provider p {
  font-weight: 700;
}

.input_file {
  display: none;
}

.custom_label {
  cursor: pointer;
  display: flex;
  font-size: 16px;
  align-items: center;
}

.span_product {
  padding: 10px 0;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
  justify-content: space-around;
}

.span_product p {
  margin: 0;
}

.form_li {
  padding: 15px 0;
  font-size: 16px;
}

.open_input {
  margin: 10px 0;
}

.button {
  display: flex;
  align-items: center;
}

.cart {
  margin: 10px 0;
}

.addProduct {
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: space-between;
}

.total {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  padding: 35px 0;
}

.input {
  /*font-size: 18px;*/
  padding: 5px 8px !important;
}
.input_form {
  /*font-size: 18px;*/
  /* padding: 0 0 0 5px; */
  display: flex;
  padding: 0;
  align-items: center;
}

.comment_title {
  display: flex;
  align-items: center;
}

.errors_text_fields {
  color: #ed5e5e;
  max-width: 270px;
  margin-top: 5px;
}

/*tr,*/
/*td,*/
/*th,*/
/*input {*/
/*  background-color: white !important;*/
/*  font-size: 18px;*/
/*   padding: 15px !important; */
/*}*/

.container {
  display: flex;
  flex-direction: column;
  /*margin-top: 40px;*/
  /* width: inherit; */
  /*vertical-align: middle;*/
  align-items: flex-start;
  position: relative;
}
.containered {
  width: inherit;
}

.feedback {
  margin-top: 5px; /* Adjust the margin as needed */
  margin-bottom: 5px;
}

.results {
  z-index: 5;
}

/* tr,
td,
th {
  padding: 14px !important;
} */

tr,
td,
th {
  padding: 8px 5px !important;
}

.input_label {
  margin-bottom: 0;
}

td:first-child,
th:first-child {
  padding-left: 20px !important;
  padding-right: 15px !important;
}

td:last-child,
th:last-child {
  /*padding-left: 25px !important;*/
  padding-right: 15px !important;
}

/* .table_reception tr,
.table_reception td,
.table_reception th {
  padding: 7px 25px !important;
} */


.checkbox {
  width: 3%;
}

.table_detail_reception tr,
.table_detail_reception td,
.table_detail_reception th {
  padding: 4px 5px !important;
}

.li_statuses input {
  margin-right: 10px;
  height: 30px;
  padding: 5px;
}
.li_statuses select {
  height: 30px;
  min-width: 130px;
  width: 130px;
  padding: 5px;
}
.li_statuses {
  position: relative;
  margin-bottom: 8px;
}
.li_statuses .mark_block {
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: -2%;
  transform: translate3d(-100%, -50%, 0);
  /*content: '';*/
  display: inline-block;
  width: 22px;
  height: 22px;
  /*background-color: cadetblue;*/
}

.cart_provider, .cart_provider_1, .cart_provider_orders, .cart_reception {
  /* border: var(--vz-border-width) solid var(--vz-input-border-custom); */
  padding: 7px;
  border-radius: var(--vz-border-radius);
}

tr {
  border-bottom: 0.2px rgba(131, 209, 253, 0.6) solid;
}
.table_modal_tr:last-child {
  border-bottom: 0;
}

.clear_option {
  background-color: white;
}

.configure_option {
  background-color: white;
  font-style: italic;
  /*font-weight: bold;*/
}
.input-step input {
  width: 2rem;
}

.header_modal {
  display: flex;
  justify-content: space-between;
}

.results table tbody tr:last-child th {
  border: none !important;
}

.results table {
  margin: 0;
}

.nav {
  display: flex;
  width: 70%;
  justify-content: space-between;
}

.table_modal_product tr th {
  font-weight: normal;
}

.results {
  font-size: 16px;
  /* border-top: 0 sandybrown; */
  /*margin: 20p;*/
  max-width: 100%;
  overflow: hidden;
  /*width: 48%;*/
  position: absolute;
  background-color: white;
  display: flex;
  padding: 10px 19px;
  border: 1px solid var(--vz-input-border-custom);
  flex-direction: column;
}
.table_supplier, .table_customer {
  width: 100%;
}

.table_supplier thead tr th,
.table_supplier tbody tr th {
  display: flex;
  justify-content: center;
}

.table_supplier tbody tr th, .td_picker {
  cursor: pointer;
  color: rgb(0, 102, 255);
  font-weight: normal;
}

.params label {
  margin-bottom: 0;
  margin-top: 0.5rem;
}

.modal_product {
  --vz-modal-width: 27%;
}
.modal_order {
  --vz-modal-width: 55%;
}
.table_modal {
  width: 100%;
}
.input-step {
  height: 27px;
  padding: 2px;
}

/* .input-step {
  height: 25px;
}

.input-step button {
  font-size: 12px;
} */
/* ORDERS */
.order_title {
  display: flex;
  align-items: center;
}
.header_order {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  align-items: flex-start;
  justify-content: space-between;
}

.container_flex_select {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.select_width,
.select_new {
  /* width: 31%; */
  min-width: 135px;
  padding: 4px 8px;
  border-radius: var(--vz-border-radius);
}

.select_width {
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
}

.select_new {
  /* width: 30%; */
  padding: 6px 4px;
}
.flex_select {
  display: flex;
  align-items: center;
  /* width: 55%; */
  justify-content: space-between;
  align-items: baseline;
}



.error {
  color: #ed5e5e;
  max-width: 170px;
  margin-top: 3px;
  line-height: 1.1;
}

.cart_order_block {
  display: flex;
    justify-content: space-between;
    
align-items: flex-start
}

.search_name {
  padding: 3px;
  border-radius: var(--vz-border-radius);
  width: 24px;
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
}
.cart_order_block_2 {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  
align-items: flex-start
}

.provider textarea {
  padding: 5px;
  width: 100%;
  max-height: 100px;
  border-radius: var(--vz-border-radius);
  border: var(--vz-border-width) solid var(--vz-input-border-custom);
}

.custom_select {
  position: relative;
}

.info {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  color: red;
  border: 1px solid #ccc;
  padding: 5px;
}

.cart_provider_orders {
  width: 48.5%;
}

.cart_provider_1 {
  width: 35%;
}

.cart_provider {
  width: 30%;
}
/* .needs-validation {
  padding: 0 10%;
} */

.select_error {
  margin-left: 10px;
}

.card {
  margin: 0 10%;
}

.exampleInputdate {
  max-width: 186px!important;
}
.status_select option {
  background-color: white;
}
/*.status_select {*/
/*  background-color: white!important;*/
/*}*/
.select_error select {
  max-width: 165px;
  padding-right: 30px;
}
/* ORDERS */


.filter_button {
  margin-left: 100px;
  min-width: 102px;
}

.filter_section {
  display: flex;
  flex-wrap: wrap;
  padding: 25px;
  justify-content: flex-start;
  min-height: 150px;
  width: 100%;
  background-color: rgba(239, 239, 239, 0.3);
  margin-top: 5px;
  /*border-bottom: 1px solid #e9ebec;*/
}

.filter_section h2 {
  font-weight: bold;
  margin-right: 60px;
}
.filter_section>div {
  width: 77%;
}
.filter_label {
  font-size: 16px;
  font-weight: bold;
}
.filter_element {
  padding-right: 10px;
  padding-left: 10px;
  color: #495057;
  margin-top: 10px;
  margin-bottom: 5px;
  width: 240px;
  margin-right: 15px;
}
.filter_element:last-child {
  margin-right: 0;
}
.filter_element_date {
  margin-bottom: 5px;
  margin-right: 20px;
  color: #495057;
  margin-top: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.date_range {
  min-width: 253px;
}


@media (max-width: 1780px) {
  .filter_element {
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
    width: 200px;
  }
  .filter_element_date {
    margin-right: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (max-width: 500px) {
  .filter_button {
    margin-left: 6px!important;
  }
  .date_range {
    min-width: unset;
  }
  .filter_element {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .filter_element_date {
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .filter_section {
    padding: 10px;
  }
}

@media (max-width: 1710px) {
  .card {
    margin: 0 5%;
  }
}

@media (max-width: 1700px) {
  .cart_block {
    width: 50%;
  }
  /*.header_modal {*/
  /*  display: flex;*/
  /*  justify-content: space-between;*/
  /*  flex-direction: column;*/
  /*  align-items: flex-start;*/
  /*  height: 80px;*/
  /*}*/
  .header_modal {
    display: flex;
    /*justify-content: space-between;*/
    /*flex-direction: column;*/
    align-items: center;
    flex-wrap: wrap;
    /*height: 80px;*/
  }
}

@media (max-width: 1600px) {
  .cart_block {
    width: 60%;
  }
  .addProduct {
    width: 100%;
  }
  .header_form {
    width: 90%;
  }
}

@media (max-width: 1550px) {
 .card {
  margin: 0;
 }
  .modal_order {
    --vz-modal-width: 60%;
  }
}
@media (max-width: 1425px) {
  .select_width {
   min-width: 100px;
  }
  .provider textarea {
    width: 100%;
  }
  .modal_order {
    --vz-modal-width: 70%;
  }
 }
@media (max-width: 1399px) {
  .select_width {
   min-width: 140px;
  }
}
@media (max-width: 1200px) {
  .order_products a {
    line-height: 1;
  }
  .modal_order {
    --vz-modal-width: 80%;
  }
}

@media (max-width: 1137px) {
  /*.header_order {*/
  /*  display: flex;*/
  /*  align-items: flex-start;*/
  /*  margin-bottom: 40px;*/
  /*  !* height: 130px; *!*/
  /*  !*flex-direction: column;*!*/
  /*  justify-content: space-between;*/
  /*}*/
  /*.flex_select {*/
  /*  margin-top: 15px;*/
  /*  width: 100%;*/
  /*  display: flex;*/
  /*  justify-content: space-between;*/
  /*  align-items: baseline;*/
  /*  flex-direction: row;*/
  /*  !* height: 139px; *!*/
  /*  !* height: 71px; *!*/
  /*}*/
}
@media (max-width: 1030px) {
  .modal_product {
    --vz-modal-width: 33%;
  }
  .modal_order {
    --vz-modal-width: 90%;
  }
}
@media (max-width: 980px) {
  .cart {
    width: 80%;
  }
  .cart_block {
    width: 100%;
  }
  .modal_product {
    --vz-modal-width: 45%;
  }
  .header_order {
    display: flex;
    align-items: flex-start;
    /*margin-bottom: 40px;*/
    margin-bottom: 25px;
    /* height: 130px; */
    flex-direction: column;
    justify-content: space-between;
  }
  .flex_select {
    /*margin-top: 15px;*/
    width: 100%;
    display: flex;
    margin-top: 25px;
    justify-content: space-evenly;
    align-items: baseline;
    flex-direction: row;
    /* height: 139px; */
    /* height: 71px; */
  }
  /*.modal_order {*/
  /*  --vz-modal-width: 65%;*/
  /*}*/
  .flex_select {
    width: 100%;
  }
  .errors_text_fields {
    max-width: 200px;
  }


}
@media (max-width: 850px) {
  .cart_order_block {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }
  .cart_provider, .cart_provider_orders, .cart_provider_1 {
    margin: 5px 0;
    width: 100%;
  }
  .block_for_carts_order {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }
  .cart_order_block_2 {
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
    width: 50%;
  }
}

@media (max-width: 800px) {
  .input_prod {
    width: auto;
  }
  .header_form {
    width: 100%;
  }
}

@media (max-width: 725px) {
  .modal_order {
    --vz-modal-width: 94%;
  }
  .header_form {
    width: 100%;
  }
  .cart_provider, .cart_provider_orders, .cart_provider_1 {
    width: 100%;
  }
  .cart_order_block_2 {
    width: 50%;
  }
  .cart_block {
    width: 100%;
  }
  .modal_product {
    --vz-modal-width: 50%;
  }
}

@media (min-width: 575px) {
  .modal_smaller {
    max-width: 400px;
    margin-top: 4%;
  }
}
@media (max-width: 580px) {
  .header_return {
    flex-direction: column;
  }
  .print_button {
    margin-top: 20px;
  }
}

@media (max-width: 570px) {


  .input_prod {
    width: 55%;
  }
  .header_order {
    display: flex;
    /* height: 160px; */
    flex-direction: column;
    align-items: flex-start;
  }
  .nav {
    width: 100%;
    margin-top: 10px;
  }
  /* .cart_provider, .cart_provider_orders {
    width: 60%;
  } */
  .flex_select {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }

  .select_error {
    display: flex;
    align-items: center;
    margin: 5px 0;
    justify-content: space-between;
  }
  .select_error select {
    max-width: unset;
  }
  .error {
    margin-left: 5px;
  }
  .block_for_carts_order {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: column;
  }
  .cart_order_block_2 {
    width: 100%;
  }
  .provider textarea {
    width: 100%;
  }

  .catalog {
    margin-top: 10px;
  }
  .header_form {
    flex-direction: column;
  }
}

@media (max-width: 510px) {
  .nav {
    margin-top: 10px;
  }
}

@media (max-width: 500px) {
  .filter_button {
    margin-left: 6px !important;
  }
}

@media (max-width: 470px) {
  .select_new {
    width: 100%;
  }
.container, .cart_reception {
  margin-bottom: 10px;
}
  .container_flex_select {
    align-items: flex-start;
    flex-direction: column;
  }
  .select_error {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }
  .input_prod {
    width: 100%;
    margin: 0;
  }
 
  .header_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .nav {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  .cart_block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .cart_provider, .cart_provider_orders, .cart_provider_1 {
    width: 100%;
  }
  .addProduct {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .select_width {
    width: 100%;
  }

}

/* PRODUCT_MODULE */
